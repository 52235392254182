import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'

const LayoutLink: FC<{ to: string; title: string; icon: ReactNode; onClick?: () => void }> = ({
  to,
  title,
  icon,
  onClick,
}) => {
  const location = useLocation()
  const isActive = location.pathname === to

  return (
    <Link
      className={classNames('app-link', { active: isActive })}
      to={to}
      data-for="app-tooltip"
      data-tip={title}
      onClick={(): void => {
        onClick && !isActive && onClick()
      }}>
      <span className="icon">{icon}</span>
    </Link>
  )
}

export default LayoutLink

import classNames from 'classnames'
import { FC } from 'react'
import ReactTooltip from 'react-tooltip'
import SimpleBar from 'simplebar-react'

import CloseButton from '../buttons/CloseButton'
import { Heading } from '../Heading'

export const LayoutDrawer: FC<{
  title: string
  isOpen?: boolean
  version?: string
  onClose: () => void
}> = ({ title, isOpen, version, onClose, children }) => {
  return (
    <div className={classNames('desktop-app__drawer', { open: isOpen })}>
      <div className="desktop-app__overlay" onClick={onClose} />
      <div className={classNames('app-drawer', { open: isOpen })}>
        <div className="app-drawer__header">
          <Heading size="md" className="app-drawer__title">
            {title}
          </Heading>
          {version && (
            <span className="app-drawer__version">
              <span className="caption">{version}</span>
            </span>
          )}
          <CloseButton className="app-drawer__close" onClick={onClose} />
        </div>
        <div className="app-drawer__body">
          <SimpleBar
            autoHide={false}
            scrollableNodeProps={{
              onScroll: (): void => {
                ReactTooltip.hide()
              },
            }}>
            {children}
          </SimpleBar>
        </div>
      </div>
    </div>
  )
}

export default LayoutDrawer

import { useWeb3React } from '@telekomconsalting/core'
import { ReactComponent as Alien } from 'app-images/icons/emoji/alien.svg'
import { ReactComponent as IconMultichart } from 'app-images/icons/emoji/multichart.svg'
import { ReactComponent as IconAPI } from 'app-images/icons/link-api.svg'
import { ReactComponent as IconBlog } from 'app-images/icons/link-blog.svg'
import { ReactComponent as IconDiscord } from 'app-images/icons/link-discord.svg'
import { ReactComponent as IconDocs } from 'app-images/icons/link-docs.svg'
import { ReactComponent as IconTwitter } from 'app-images/icons/link-twitter.svg'
import { ReactComponent as Notification } from 'app-images/icons/notification.svg'
import { ReactComponent as SettingsIcon } from 'app-images/icons/setting.svg'
import { ReactComponent as SoundOff } from 'app-images/icons/sound-off.svg'
import { ReactComponent as SoundOn } from 'app-images/icons/sound-on.svg'
import { ReactComponent as IconTicket } from 'app-images/icons/ticket.svg'
import { ReactComponent as IconTopCryptos } from 'app-images/icons/top-cryptos.svg'
import { ReactComponent as LogoDexGuruImg } from 'app-images/logo-icon.svg'
import classNames from 'classnames'
import React, { FC, Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Route, Switch, useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import SimpleBar from 'simplebar-react'

import { markNotifications } from '../../actions/notificationsAction'
import {
  setDataDisclaimerOpen,
  setNotificationsOpen,
  setSettingsOpen,
} from '../../actions/uiActions'
import { appGitVersion } from '../../config/settings'
import { AmplitudeEvent } from '../../constants/amplitudeEvents'
import { useAuth } from '../../containers/AuthWrapper'
import { financialFormat } from '../../helpers/priceAndAmountHelpers'
import { State } from '../../reducers'
import {
  MULTICHART_ROUTE,
  PRICING_ROUTE,
  SEASON_PASS_ROUTE,
  TOP_CRYPTOS_NETWORK_ALL_ASSETS_TEMPLATE,
  TOP_CRYPTOS_NETWORK_ASSETS_TEMPLATE,
  TOP_CRYPTOS_NETWORK_TEMPLATE,
  TOP_CRYPTOS_ROUTE,
} from '../../routes'
import amplitudeService from '../../services/amplitudeService'
import {
  getSoundsDisabledFromLocalStorage,
  setSoundsDisabledToLocalStorage,
} from '../../services/preferencesService'
import { playSoundToggleSound } from '../../services/soundService'
import BlinkIndicator from '../BlinkIndicator'
import { useRouteChange } from '../ChatWidget/chatHooks'
import { useConnectors } from '../ConnectorsContext'
import { DashboardDesktop } from '../Dashboard/DashboardDesktop'
import { MAX_NOTIFICATIONS_NUMBER } from '../Notifications/resources'
import ProtectedRoute from '../ProtectedRoute'
import LayoutDrawer from './LayoutDrawer'
import LayoutLink from './LayoutLink'

export const version = `Beta ${appGitVersion}`

const Multichart = React.lazy(() => import(/* webpackPrefetch: true */ '../Multichart/Multichart'))
const NotificationsAlertsMain = React.lazy(() => import('../Notifications/NotificationsAlertsMain'))

const SeasonPass = React.lazy(() => import(/* webpackPrefetch: true */ '../SeasonPass/SeasonPass'))
const TopCryptos = React.lazy(() => import(/* webpackPrefetch: true */ '../TopCryptos/TopCryptos'))
const Pricing = React.lazy(() => import(/* webpackPrefetch: true */ '../Pricing'))

const Settings = React.lazy(() => import(/* webpackPrefetch: true */ '../Settings'))
const DataDisclaimer = React.lazy(
  () => import(/* webpackPrefetch: true */ '../DataDisclaimer/DataDisclaimer')
)

export const LayoutDesktop: FC = () => {
  const { isAuth, openProviderMenu } = useAuth()
  const { account, connector } = useWeb3React()
  const { isSettingsOpen } = useSelector((state: State) => state.ui)
  const { isDataDisclaimerOpen } = useSelector((state: State) => state.ui)
  const { isNotificationsOpen } = useSelector((state: State) => state.ui)
  const { lastNotification, notifications } = useSelector((state: State) => state.notifications)
  const [drawerTitle, setDrawerTitle] = useState('')
  const reduxDispatch = useDispatch()
  const [soundDisabled, setSoundDisabled] = useState<boolean>(getSoundsDisabledFromLocalStorage())
  const { walletConnectors } = useConnectors()
  const onOpenNotifications = (): void => {
    if (isNotificationsOpen) {
      handleDrawerClose()
      return
    }
    amplitudeService.sendEvent(AmplitudeEvent.ALERTS_AND_NOTIFICATIONS_SIDEBAR_OPENED)
    setDrawerTitle('Alerts & Notifications')
    reduxDispatch(setNotificationsOpen(true))
    reduxDispatch(setDataDisclaimerOpen(false))
    reduxDispatch(setSettingsOpen(false))
  }

  const onOpenDataDisclaimer = (): void => {
    if (isDataDisclaimerOpen) {
      handleDrawerClose()
      return
    }
    setDrawerTitle('Data Disclaimer')
    reduxDispatch(setDataDisclaimerOpen(true))
    reduxDispatch(setSettingsOpen(false))
    reduxDispatch(setNotificationsOpen(false))
  }

  const onOpenSettings = (): void => {
    if (!isAuth) {
      openProviderMenu(doOpenSettings)
    } else {
      doOpenSettings()
    }
  }

  const doOpenSettings = async (): Promise<void> => {
    if (isSettingsOpen) {
      handleDrawerClose()
      return
    }
    const providerName = await walletConnectors?.getProviderName(connector)
    amplitudeService.sendEvent(AmplitudeEvent.OPENED_SETTINGS_MODAL, {
      wallet_provider: providerName,
      wallet_address: account,
    })
    setDrawerTitle('Settings')
    reduxDispatch(setSettingsOpen(true))
    reduxDispatch(setDataDisclaimerOpen(false))
    reduxDispatch(setNotificationsOpen(false))
  }

  const handleDrawerClose = (): void => {
    reduxDispatch(setNotificationsOpen(false))
    reduxDispatch(setSettingsOpen(false))
    reduxDispatch(setDataDisclaimerOpen(false))
  }

  const [unseenNotificationsCount, setUnseenNotificationsCount] = useState(0)

  useEffect((): void => {
    if (lastNotification) {
      const unread = notifications.filter((n) => n.created_at > lastNotification.created_at).length
      setUnseenNotificationsCount(Math.min(MAX_NOTIFICATIONS_NUMBER, unread))
    } else {
      setUnseenNotificationsCount(Math.min(MAX_NOTIFICATIONS_NUMBER, notifications.length))
    }
  }, [notifications, lastNotification])

  const openClickSound = async (e: React.MouseEvent): Promise<void> => {
    const newValue = !soundDisabled
    e.preventDefault()

    setSoundsDisabledToLocalStorage(newValue)
    setSoundDisabled(newValue)
    await playSoundToggleSound()

    amplitudeService.sendEvent(AmplitudeEvent.TOGGLED_SOUND_EFFECTS, {
      sounds_effects_status: newValue ? 'on' : 'off',
    })
  }

  const history = useHistory()
  useRouteChange(history)

  const notificationsTooltip = `Alerts & Notifications${
    unseenNotificationsCount
      ? `<br />${unseenNotificationsCount} unread notification${
          unseenNotificationsCount > 1 ? 's' : ''
        }`
      : ''
  }`

  return (
    <div className="desktop-app">
      <div className="desktop-app__nav">
        <div className="app-nav">
          <div className="app-nav__header">
            <Link to="/" className="app-link app-link--main">
              <span className="icon">
                <LogoDexGuruImg className="logo__icon" />
              </span>
            </Link>
          </div>

          {process.env.REACT_APP_NOTIFICATIONS === 'true' && account && (
            <button
              aria-label="Notification"
              className={classNames('app-link app-link--notifications', {
                active: isNotificationsOpen,
              })}
              data-for="app-tooltip"
              data-tip={notificationsTooltip}
              data-indicator={
                unseenNotificationsCount
                  ? financialFormat(unseenNotificationsCount, {
                      roundBigNumbersFrom: 'K',
                    })
                  : undefined
              }
              onClick={onOpenNotifications}>
              <span className="icon">
                <Notification />
              </span>
            </button>
          )}

          <div className="app-nav__body">
            <SimpleBar
              scrollableNodeProps={{
                onScroll: ReactTooltip.hide,
              }}>
              <div className="app-nav__wrapper">
                <div className="app-nav__links">
                  {process.env.REACT_APP_MARKET_CAP === 'true' && (
                    <LayoutLink
                      to={TOP_CRYPTOS_ROUTE}
                      title="Top Cryptos by Market Cap"
                      icon={<IconTopCryptos />}
                    />
                  )}

                  <LayoutLink
                    to={MULTICHART_ROUTE}
                    title="Multi-Chart"
                    icon={<IconMultichart />}
                    onClick={(): void => {
                      amplitudeService.sendEvent(AmplitudeEvent.CLICKED_ON_MULTICHART_IN_HEADER)
                    }}
                  />

                  <LayoutLink
                    to={SEASON_PASS_ROUTE}
                    title="Pre-DAO NFT Season Pass"
                    icon={<IconTicket />}
                  />

                  {process.env.REACT_APP_TGE_PRICING === 'true' && (
                    <LayoutLink to={PRICING_ROUTE} title="Plans &amp; Pricing" icon={<Alien />} />
                  )}
                </div>

                <div className="app-nav__links app-nav__links--extra">
                  <div className="app-nav__links-onboarding" id="onboarding-docs" />
                  <button
                    onClick={onOpenDataDisclaimer}
                    className={classNames('app-link', { active: isDataDisclaimerOpen })}
                    data-tip="Data Disclaimer"
                    aria-label="Data Disclaimer"
                    data-for="app-tooltip">
                    <span className="icon">
                      <BlinkIndicator state="good" speed="slow" stop={true} />
                    </span>
                  </button>
                  <a
                    href="https://blog.dex.guru/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="app-link"
                    aria-label="Blog"
                    data-tip="Blog"
                    data-place="right"
                    data-for="app-tooltip">
                    <span className="icon">
                      <IconBlog />
                    </span>
                  </a>
                  <a
                    href="https://docs.dex.guru/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="app-link"
                    data-tip="Docs"
                    data-place="right"
                    data-for="app-tooltip">
                    <span className="icon">
                      <IconDocs />
                    </span>
                  </a>
                  <a
                    href="https://discord.gg/dPW8fzwzz9"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="app-link"
                    data-tip="Discord"
                    data-place="right"
                    data-for="app-tooltip">
                    <span className="icon">
                      <IconDiscord />
                    </span>
                  </a>
                  <a
                    href="https://twitter.com/dexguru"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="app-link"
                    data-tip="Twitter"
                    data-for="app-tooltip">
                    <span className="icon">
                      <IconTwitter />
                    </span>
                  </a>
                  <a
                    href="https://developers.dex.guru/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="app-link"
                    data-tip="API"
                    data-place="right"
                    data-for="app-tooltip">
                    <span className="icon">
                      <IconAPI />
                    </span>
                  </a>
                </div>
              </div>
            </SimpleBar>
          </div>

          <div className="app-nav__footer">
            <button
              aria-label="Sound effect"
              onClick={openClickSound}
              data-for="app-tooltip"
              data-tip="Click to toggle sound Effects on&nbsp;or&nbsp;off"
              className={classNames(
                'app-link app-link--sound',
                { on: !soundDisabled },
                { off: soundDisabled }
              )}>
              <span className="icon">
                {!soundDisabled && <SoundOn className="icon-sound icon-sound--on" />}
                {soundDisabled && <SoundOff className="icon-sound icon-sound--off" />}
              </span>
            </button>
            <button
              aria-label="Settings"
              className={classNames('app-link app-link--settings', {
                active: isSettingsOpen,
              })}
              data-for="app-tooltip"
              data-tip="Settings">
              <span className="icon" onClick={onOpenSettings}>
                <SettingsIcon />
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="desktop-app__body">
        <div className="desktop-app__pane">
          <Switch>
            {process.env.REACT_APP_MARKET_CAP === 'true' && (
              <Route
                exact
                path={[
                  TOP_CRYPTOS_ROUTE,
                  TOP_CRYPTOS_NETWORK_TEMPLATE,
                  TOP_CRYPTOS_NETWORK_ASSETS_TEMPLATE,
                  TOP_CRYPTOS_NETWORK_ALL_ASSETS_TEMPLATE,
                ]}>
                <Suspense fallback={<div />}>
                  <TopCryptos />
                </Suspense>
              </Route>
            )}

            {process.env.REACT_APP_TGE_PRICING === 'true' && (
              <Route exact path={PRICING_ROUTE}>
                <Suspense fallback={<div />}>
                  <Pricing />
                </Suspense>
              </Route>
            )}

            <ProtectedRoute exact path={MULTICHART_ROUTE}>
              <Suspense fallback={<div />}>
                <Multichart />
              </Suspense>
            </ProtectedRoute>
            <ProtectedRoute exact path={SEASON_PASS_ROUTE}>
              <Suspense fallback={<div />}>
                <SeasonPass />
              </Suspense>
            </ProtectedRoute>
            <Route path="/*">
              <Suspense fallback={<div />}>
                <DashboardDesktop />
              </Suspense>
            </Route>
          </Switch>
        </div>
        <LayoutDrawer
          isOpen={isSettingsOpen || isNotificationsOpen || isDataDisclaimerOpen}
          title={drawerTitle}
          version={isSettingsOpen ? version : undefined}
          onClose={(): void => {
            // do not reset counter if any other drawer is closed than notifications
            if (isNotificationsOpen) {
              reduxDispatch(markNotifications(notifications))
            }
            handleDrawerClose()
          }}>
          {isSettingsOpen && (
            <Suspense fallback={<></>}>
              <Settings isMobile={false} />
            </Suspense>
          )}
          {isNotificationsOpen && (
            <Suspense fallback={<></>}>
              <NotificationsAlertsMain unseenNotificationsCount={unseenNotificationsCount} />
            </Suspense>
          )}
          {isDataDisclaimerOpen && (
            <Suspense fallback={<div />}>
              <DataDisclaimer />
            </Suspense>
          )}
        </LayoutDrawer>
      </div>
    </div>
  )
}
